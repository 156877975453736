import React, { useRef, useEffect } from 'react';

export default function StandardBackground() {
  const canvasRef = useRef(null);
  const cellSize = 40;

  function getSettingsByZoomLevel(gridLineSpacing) {
    const fontFamily = 'source-han-sans-korean, sans-serif';
    return {
      fontSize: 12,
      font: `${200} ${12}px ${fontFamily}`,
      rowLabelX: 0.125 * cellSize, // numbers
      rowLabelY: (gridLineSpacing / 2) * cellSize,
      colLabelX: (gridLineSpacing / 2) * cellSize, // letters
      colLabelY: 0 * cellSize + 12
    };
  }

  function drawGridLines(ctx) {
    ctx.globalAlpha = 0.9;
    ctx.strokeStyle = '#cccccc';
    ctx.lineWidth = 1;
    const numRows = window.innerHeight / cellSize;
    const numCols = window.innerWidth / cellSize;

    for (let row = 0; row < numRows; row += 7) {
      ctx.beginPath();
      ctx.moveTo(0, row * cellSize);
      ctx.lineTo(ctx.canvas.width, row * cellSize);
      ctx.stroke();
    }
    for (let col = 0; col < numCols; col += 7) {
      ctx.beginPath();
      ctx.moveTo(col * cellSize, 0);
      ctx.lineTo(col * cellSize, ctx.canvas.height);
      ctx.stroke();
    }
    ctx.globalAlpha = 1;
  }

  function numberToLetterCount(index) {
    let runningIndex = index;
    const baseChar = 'A'.charCodeAt(0);
    let letters = '';
    do {
      runningIndex -= 1;
      letters = String.fromCharCode(baseChar + (runningIndex % 26)) + letters;
      runningIndex = (runningIndex > 25) ? (runningIndex / 26) : 0;
    } while (runningIndex > 0);
    return letters;
  }

  function drawGridAnnotations(ctx) {
    ctx.globalAlpha = 0.9;
    const gridLineSpacing = 7;
    const numRows = ctx.canvas.height / cellSize;
    const numCols = ctx.canvas.width / cellSize;
    const settings = getSettingsByZoomLevel(gridLineSpacing);
    ctx.font = settings.font;
    ctx.fillStyle = '#cccccc';
    ctx.textAlign = 'center';

    let rowCount = 0;
    for (
      let row = 0;
      row <= numRows + gridLineSpacing;
      row += gridLineSpacing
    ) {
      let colCount = 1;
      for (let col = 0; col <= numCols; col += gridLineSpacing) {
        ctx.fillText(
          rowCount,
          col * cellSize
            + settings.rowLabelX
            + ctx.measureText(rowCount).width / 2,
          row * cellSize
            + settings.rowLabelY
            + settings.fontSize * 0.25
        );
        ctx.fillText(
          numberToLetterCount(colCount),
          col * cellSize + settings.colLabelX,
          row * cellSize + settings.colLabelY
        );
        colCount += 1;
      }
      rowCount += 1;
    }
    ctx.globalAlpha = 1;
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.style.border = 'none';
    ctx.canvas.width = window.innerWidth;
    ctx.canvas.height = window.innerHeight;
    drawGridLines(ctx);
    drawGridAnnotations(ctx);
  }, []);

  return (
    <canvas className="pages-canvas" ref={canvasRef} />
  );
}
