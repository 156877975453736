import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

export default function Result(props) {
  const { data } = props;

  const mediaHref = `/media/${data.slug.current}`;

  function getDateString() {
    if (data.date.length === 4) return data.date?.substring(0, 4);
    let monthString;
    switch (data.date.substring(5, 7)) {
      case '01':
        monthString = 'Jan'; break;
      case '02':
        monthString = 'Feb'; break;
      case '03':
        monthString = 'Mar'; break;
      case '04':
        monthString = 'Apr'; break;
      case '05':
        monthString = 'May'; break;
      case '06':
        monthString = 'Jun'; break;
      case '07':
        monthString = 'Jul'; break;
      case '08':
        monthString = 'Aug'; break;
      case '09':
        monthString = 'Sep'; break;
      case '10':
        monthString = 'Oct'; break;
      case '11':
        monthString = 'Nov'; break;
      case '12':
        monthString = 'Dec'; break;
      default:
        // eslint-disable-next-line no-console
        console.error(data.date.substring(5, 7));
        throw new Error('Unexpected month value');
    }
    if (data.date.length === 7) {
      return `${data.date.substring(0, 4)} ${monthString}`;
    }
    let dayString;
    if (data.date[8] === '0') {
      // eslint-disable-next-line prefer-destructuring
      dayString = data.date[9];
    } else {
      dayString = data.date.substring(8, 10);
    }
    return `${data.date.substring(0, 4)} ${monthString} ${dayString}`;
  }

  return (
    data.mediaCategory[0].title !== 'Online Videos' && data.mediaCategory[0].title !== 'Media Coverage'
      ? ( // "Writings", "Online Videos", "Featured Publications"
        <Link className="result-block" to={mediaHref}>
          {data.mediaCategory[0].title !== 'Media Coverage' && data.mediaCategory[0].title !== 'Online Videos'
            ? <GatsbyImage className="result-image" image={data.mainImage?.asset.gatsbyImageData} alt={data?.title} />
            : <div className="image-placeholder" />}
          <div className="result-content">
            <div className="result-header">
              <p className="media">{getDateString()}</p>
              <div className="titles media">
                <h3 className="media">{data?.title}</h3>
                <h2 className="media korean-title">{data.titleKr}</h2>
              </div>
            </div>
          </div>
        </Link>
      )
      : ( // "Online Videos", "Media Coverage"
        <a className="result-block" href={data.externalUrl} target="_blank" rel="noreferrer">
          {data.mediaCategory[0].title !== 'Media Coverage' && data.mediaCategory[0].title !== 'Online Videos'
            ? <GatsbyImage className="result-image" image={data.mainImage?.asset.gatsbyImageData} alt={data?.title} />
            : <div className="image-placeholder" />}
          <div className="result-content">
            <div className="result-header">
              <p className="media">{getDateString()}</p>
              <div className="titles media">
                <h3 className="media">{data?.title}</h3>
                <h2 className="media korean-title">{data.titleKr}</h2>
              </div>
            </div>
          </div>
        </a>
      )
  );
}
