/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Link } from 'gatsby';

export default function CategoryMenuSelector(props) {
  const {
    category, categoryType, activeCategory, categoryCount
  } = props;
  const classNameString = activeCategory === category ? 'active' : '';
  const slugString = category.replace(/\s/g, '').toLowerCase();
  const slugFolderName = categoryType === 'Works' ? 'works' : 'media';

  return (
    <li className={classNameString}>
      <Link to={`/${slugFolderName}/${slugString}`}>
        <p className="category-name">{category}</p>
        <p className="category-count">{categoryCount}</p>
      </Link>
    </li>
  );
}
