import React from 'react';
import { Link } from 'gatsby';

export default function TitleBlock(props) {
  const { activeCategory, place } = props;

  function getClassName() {
    switch (place) {
      case 'media':
        return 'title-block media-item-title';
      default:
        return 'title-block';
    }
  }

  return (
    <div className="title-block-container">
      <h1 className={getClassName()}>{activeCategory}</h1>
      {activeCategory === 'Featured Publications' || activeCategory === 'Writings'
        ? <Link to="/works/publication">See PRAUD Publications</Link>
        : null}
    </div>
  );
}
