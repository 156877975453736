import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import Footer from './Footer';
import NavigationSet from './NavigationSet';
import StandardBackground from './StandardBackground';

function GeneralLayout({ children }) {
  useEffect(() => {
    <script>
      {(function (d) {
        const config = {
          kitId: 'uok3kii',
          scriptTimeout: 3000,
          async: true
        };
        const h = d.documentElement;
        const t = setTimeout(() => {
          h.className = `${h.className.replace(/\bwf-loading\b/g, '')} wf-inactive`;
        }, config.scriptTimeout);
        const tk = d.createElement('script');
        let f = false;
        const s = d.getElementsByTagName('script')[0];
        let a;

        h.className += ' wf-loading';
        tk.src = `https://use.typekit.net/${config.kitId}.js`;
        tk.async = true;
        // eslint-disable-next-line no-multi-assign
        tk.onload = tk.onreadystatechange = function () {
          // eslint-disable-next-line react/no-this-in-sfc
          a = this.readyState;
          if (f || (a && a !== 'complete' && a !== 'loaded')) return;
          f = true;
          clearTimeout(t);
          try {
            // eslint-disable-next-line no-undef
            Typekit.load(config);
          } catch (e) {
            throw new Error('Something happened with TypeKit.');
          }
        };
        s.parentNode.insertBefore(tk, s);
      }(document))}
    </script>;
  });

  return (
    <>
      <StandardBackground />
      <main>{children}</main>
      <Footer />
      <NavigationSet general />
    </>
  );
}

GeneralLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default GeneralLayout;
