/* eslint-disable react/button-has-type */
import React, { useState, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export default function studionews() {
  const listQuery = useStaticQuery(graphql`
    query {
      allSanityAwardPastNews(sort: {order: ASC, fields: _createdAt}) {
        nodes {
          category
          date
          title
        }
      }}
    `);
  const pastNews = listQuery.allSanityAwardPastNews.nodes.filter((node) => node.category === 'PN');
  const [pastNewsStatus, setPastNewsStatus] = useState('closed');
  const contentsRef = useRef(null);

  function handleClick(e) {
    e.preventDefault();
    switch (pastNewsStatus) {
      case 'closed':
        contentsRef.current.className = 'contents';
        setPastNewsStatus('open');
        break;
      case 'open':
        contentsRef.current.className = 'contents hide';
        setPastNewsStatus('closed');
        break;
      default:
        throw new Error('Unexpected value of pastNewsStatus');
    }
  }

  function getButtonText() {
    if (pastNewsStatus === 'closed') return 'See Past News';
    return 'Close Past News';
  }

  return (
    <div className="past-news">
      <button type="button" onClick={handleClick}>{getButtonText()}</button>
      <div ref={contentsRef} className="contents hide">
        <p className="label">Past News</p>
        {pastNews.map((pastNewsItem) => (
          <div className="past-news-item">
            <p className="date">{pastNewsItem.date}</p>
            <p className="past-news-title">{pastNewsItem.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
