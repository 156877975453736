import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function Footer() {
  return (
    <div className="footer">
      <div className="contents">
        <StaticImage className="footer-logo" src="../images/PRAUD-footer-logo.png" alt="PRAUD Logo" width={40} />
        <div className="copyright">© Copyright 2022. All rights are reserved by PRAUD</div>
      </div>
    </div>
  );
}
